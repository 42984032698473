import { render, staticRenderFns } from "./BlogSection.vue?vue&type=template&id=3a197eb7&scoped=true&"
import script from "./BlogSection.vue?vue&type=script&lang=ts&"
export * from "./BlogSection.vue?vue&type=script&lang=ts&"
import style0 from "./BlogSection.vue?vue&type=style&index=0&id=3a197eb7&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a197eb7",
  null
  
)

/* custom blocks */
import block0 from "./BlogSection.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseText.vue').default,BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default,CategoryPostTabs: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BlogSection/CategoryPostTabs.vue').default})
